.connecting-btn {
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.connecting-btn img{
    width: 80%;
}
.connecting-bg .modal-content{     
    background: linear-gradient(to right, #0B1526, #357BFB, 100%, #210031 100%);
    margin-bottom: 0;
}
.qr-image {
    background:#FFF;
    padding:10px;
}

.qr-copy { 
    background-color: white;
    padding: 1px 5px;
    border-radius: 5px; 
  }

  @media (max-width: 568px) {
    .qr-copy {
        color: #518eff;
      display: inline-block;
      font-size: 16px;
      position: relative;
      right: 20px;
      top: 18px;
      z-index: 1;
      margin-top: -40px;
    }
    .qr-image {
      margin-bottom: 40px; 
    }
}