.particles.particle-1 {
    background-image: url(../images/slider/slider-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 900px;
}

.particles.particle-2 {
    background-image: url(../images/bg2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 900px;
}